import { useEffect, useRef, useState } from "react";
import Editor from "../../../component/board/Editor";
import { Box } from "../../../component/box/css/styled.box";
import Layout from "../../../component/layout/Layout";
import { VolunteerEditorBox } from "./css/styled.volunteer";
import { InputBox, SelectBox } from "../../../component/form/Form";
import { useChangeHandler } from "../../../common/CommonUtil";
import { volunteerForm } from "./init";
import { Button } from "../../../component/button/css/styled.button";
import { ActionButton } from "../../../component/button/ActionButton";
import { Li } from "../../../component/layout/css/styled.layout";
import useVolunteerService from "./useVolunteerService";
import VolunteerImage from "./VolunteerImage";

function VolunteerWrite() {
    const { state, onChangeHandler } = useChangeHandler(volunteerForm);

    const { getShelterList, postVolunteer } = useVolunteerService();
    const [content, setContent] = useState();
    const [image, setImage] = useState();

    const [shelterList, setShelterList] = useState([]);

    useEffect(() => {
        const query = {};
        getShelterList({ query, setShelterList });
    }, []);

    const onClickWrite = () => {
        state.userId = sessionStorage.getItem("userId");
        state.volunteerContent = content;

        const save = new Blob([JSON.stringify(state)], {
            type: "application/json",
        });

        const formData = new FormData();
        formData.append("save", save);
        formData.append("file", image);

        postVolunteer(formData);
    };

    const button = () => {
        return (
            <Li key={"write"}>
                <Button onClick={() => onClickWrite()}>저장</Button>
            </Li>
        );
    };

    return (
        <Layout>
            <VolunteerEditorBox>
                <VolunteerImage volunteer={state} setImage={setImage} />
                <Box style={{ padding: "20px 0" }}>
                    <InputBox
                        label="제목"
                        type="text"
                        width="100%"
                        labelWidth="15%"
                        height="50px"
                        id="volunteerTitle"
                        value={state.volunteerTitle}
                        onChangeHandler={onChangeHandler}
                    />
                    <Box style={{ display: "flex" }}>
                        <InputBox
                            label="날짜"
                            type="text"
                            width="100%"
                            labelWidth="30%"
                            height="50px"
                            id="volunteerDate"
                            value={state.volunteerDate}
                            onChangeHandler={onChangeHandler}
                        />
                        <SelectBox
                            label="보호소"
                            width="100%"
                            labelWidth="30%"
                            height="50px"
                            id="shelterCode"
                            value={state.shelterCode}
                            onChangeHandler={onChangeHandler}
                            options={shelterList}
                        />
                    </Box>
                </Box>
                <Editor
                    content={content}
                    setContent={setContent}
                    type={"volunteer"}
                />
            </VolunteerEditorBox>
            <ActionButton button={button} type={"admin"} />
        </Layout>
    );
}

export default VolunteerWrite;
