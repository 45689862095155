export const withthedogs = () => {
    return (
        window.location.host === "withthedogs.co.kr" ||
        window.location.host === "www.withthedogs.co.kr"
    );
};

export const withthedogsAdmin = () => {
    return sessionStorage.getItem("withthedogs") === "manager";
};
