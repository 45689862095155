import { useNavigate } from "react-router-dom";
import { Li } from "../../../component/layout/css/styled.layout";
import { useEffect, useState } from "react";
import { ActionButton } from "../../../component/button/ActionButton";
import useBoardService from "../useBoardService";
import { Button } from "../../../component/button/css/styled.button";
import { NoticeBoardBox } from "./css/styled.NoticeBoard";
import Layout from "../../../component/layout/Layout";
import Pagination from "../../../component/pagination/Pagination";

function NoticeBoard() {
    const navigate = useNavigate();

    const [currentPage, setCurrentPage] = useState(1);
    const [page, setPage] = useState();
    const [noticeList, setNoticeList] = useState([]);
    const { getNoticeBoardList } = useBoardService();
    useEffect(() => {
        const query = {
            page: currentPage - 1,
            size: 10,
        };
        getNoticeBoardList({ query, setNoticeList, setPage });
    }, [currentPage]);

    const onChagePage = (page) => {
        setCurrentPage(page);
    };

    const onClickBoard = (id) => {
        navigate(`/notice/board/${id}`);
    };

    const onClickWrite = () => {
        navigate("/notice/board/write");
    };

    const button = () => {
        return (
            <Li key={"write"}>
                <Button onClick={() => onClickWrite()}>글쓰기</Button>
            </Li>
        );
    };

    return (
        <Layout>
            <NoticeBoardBox>
                <table
                    style={{
                        width: "100%",
                        border: "1px solid gray",
                        borderCollapse: "collapse",
                    }}
                >
                    <thead>
                        <tr
                            style={{
                                height: "40px",
                                border: "1px solid #ccc",
                            }}
                        >
                            <th style={{ width: "70%" }}>제목</th>
                            <th style={{ width: "30%" }}>작성자</th>
                        </tr>
                    </thead>
                    <tbody>
                        {noticeList.map((item) => (
                            <tr
                                key={item.id}
                                style={{
                                    height: "50px",
                                    textAlign: "center",
                                    border: "1px solid #ccc",
                                }}
                                onClick={() => onClickBoard(item.id)}
                            >
                                <td>{item.noticeBoardTitle}</td>
                                <td>{item.userId}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </NoticeBoardBox>
            {page && (
                <Pagination
                    currentPage={currentPage}
                    totalElements={page.totalElements}
                    onChangePage={onChagePage}
                />
            )}
            <ActionButton button={button} />
        </Layout>
    );
}

export default NoticeBoard;
