import Pagination from "react-js-pagination";
import styled from "styled-components";

export const PaginationBox = styled.div`
    text-align: center;
    display: flex;
    height: 50px;
    width: 500px;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    @media (max-width: 768px) {
        width: 100%;
    }
`;

export const PaginationButton = styled(Pagination)``;
