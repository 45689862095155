import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Layout from "../../../component/layout/Layout";
import { VolunteerEditorBox } from "./css/styled.volunteer";
import { Box } from "../../../component/box/css/styled.box";
import { InputBox, SelectBox } from "../../../component/form/Form";
import Editor from "../../../component/board/Editor";
import useVolunteerService from "./useVolunteerService";
import { ActionButton } from "../../../component/button/ActionButton";
import { Li } from "../../../component/layout/css/styled.layout";
import { Button } from "../../../component/button/css/styled.button";
import VolunteerImage from "./VolunteerImage";

function VolunteeDetail() {
    const navigate = useNavigate();
    let { id } = useParams();
    const { getVolunteer, getShelterList, deleteVolunteer } =
        useVolunteerService();

    const [image, setImage] = useState();

    const [volunteer, setVolunteer] = useState();
    const [shelterList, setShelterList] = useState([]);
    useEffect(() => {
        const query = {
            id: id,
        };

        getShelterList({ query, setShelterList });
        getVolunteer({ query, setVolunteer });
    }, []);

    const onClickUpdate = () => {
        navigate(`/withthedogs/volunteer/update/${id}`);
    };

    const onClickDelete = () => {
        const query = {
            id: id,
        };
        deleteVolunteer({ query });
    };

    const button = () => {
        return (
            <>
                <Li key={"write"}>
                    <Button onClick={() => onClickUpdate()}>수정</Button>
                </Li>
                <Li key={"update"}>
                    <Button onClick={() => onClickDelete()}>삭제</Button>
                </Li>
            </>
        );
    };

    return (
        <Layout>
            {volunteer && (
                <VolunteerEditorBox>
                    <VolunteerImage volunteer={volunteer} setImage={setImage} />
                    <Box style={{ padding: "20px 0" }}>
                        <InputBox
                            label="제목"
                            type="text"
                            disabled={true}
                            width="100%"
                            labelWidth="15%"
                            height="50px"
                            id="volunteerTitle"
                            value={volunteer.volunteerTitle}
                        />
                        <Box style={{ display: "flex" }}>
                            <InputBox
                                label="날짜"
                                type="text"
                                disabled={true}
                                width="100%"
                                labelWidth="30%"
                                height="50px"
                                id="volunteerDate"
                                value={volunteer.volunteerDate}
                            />
                            <SelectBox
                                label="보호소"
                                disabled={true}
                                width="100%"
                                labelWidth="30%"
                                height="50px"
                                id="shelterCode"
                                value={volunteer.shelterCode}
                                options={shelterList}
                            />
                        </Box>
                    </Box>
                    <Editor
                        content={volunteer.volunteerContent}
                        setContent={null}
                        type={"volunteer"}
                        readOnly={true}
                    />
                </VolunteerEditorBox>
            )}
            <ActionButton navi={null} button={button} type={"admin"} />
        </Layout>
    );
}

export default VolunteeDetail;
