import styled from "styled-components";

export const DogListBox = styled.div`
    display: grid;
    width: 100%;
    min-height: calc(100vh - 400px);
    grid-template-columns: repeat(5, 1fr);
    gap: 20px;
    padding: 0 200px;
    margin-bottom: 50px;
    align-items: center;

    @media (max-width: 768px) {
        display: flex;
        min-height: calc(100vh - 500px);
        padding: 0 30px;
        flex-direction: column;
        align-items: center;
    }
`;

export const DogMainBox = styled.div`
    display: block;
    min-height: calc(100vh - 100px);
    align-content: center;

    @media (max-width: 768px) {
        display: inline;
        height: 100%;
    }
`;

export const DogBox = styled.div`
    text-align: ${(props) => props.textAlign || "start"};
    width: 250px;
    height: 350px;
    align-items: center;
    border: 2px solid ${(props) => props.color || "#ADD8E6"};
    border-radius: 20px;

    @media (max-width: 768px) {
        width: 250px;
        height: 350px;
    }
`;

export const DogListImageBox = styled.div`
    text-align: ${(props) => props.textAlign || "start"};
    display: block;
    width: 220px;
    height: 220px;
    margin: 10px auto;
    border: 2px solid #add8e6;
    border-radius: 20px;
    justify-content: center;
    align-items: center;

    @media (max-width: 768px) {
        width: 220px;
        height: 220px;
    }
`;

export const DogNameBox = styled.div`
    text-align: ${(props) => props.textAlign || "start"};
    display: flex;
    height: 30px;
    justify-content: center;
    align-items: center;
`;

export const LikePeopleBox = styled.div`
    text-align: ${(props) => props.textAlign || "start"};
    display: flex;
    height: 30px;
    justify-content: center;
    align-items: center;
`;

export const DogButtonBox = styled.div`
    display: flex;
`;
