import { useEffect, useState } from "react";
import { Nav, Ul } from "../layout/css/styled.layout";
import { Box } from "../box/css/styled.box";
import { withbinaryAdmin } from "../../common/WithbinaryUtil";
import { withthedogsAdmin } from "../../common/WithTheDogsUtil";
import { admin } from "../../common/SessionUtil";

export const ActionButton = ({ button, type }) => {
    const [isAuth, setIsAuth] = useState(false);
    useEffect(() => {
        if (admin()) {
            setIsAuth(true);
        }

        if (withbinaryAdmin() && type === "withbinary") {
            setIsAuth(true);
        }

        if (withthedogsAdmin() && type === "withthedogs") {
            setIsAuth(true);
        }
    }, []);

    return (
        <Box
            style={{
                display: "flex",
                width: "100%",
                alignItems: "center",
            }}
        >
            {isAuth ? (
                <Nav>
                    <Ul>{button()}</Ul>
                </Nav>
            ) : null}
        </Box>
    );
};
