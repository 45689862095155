import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useBoardService from "../useBoardService";
import { Li } from "../../../component/layout/css/styled.layout";
import { Button } from "../../../component/button/css/styled.button";
import Layout from "../../../component/layout/Layout";
import { NoticeBoardBox } from "./css/styled.NoticeBoard";
import { InputBox } from "../../../component/form/Form";
import Editor from "../../../component/board/Editor";
import { ActionButton } from "../../../component/button/ActionButton";
import { Box } from "../../../component/box/css/styled.box";

function NoticeBoardDetail() {
    const navigate = useNavigate();
    let { id } = useParams();
    const { getNoticeBoard, deleteNoticeBoard } = useBoardService();

    const [noticeBoard, setNoticeBoard] = useState();
    useEffect(() => {
        const query = {
            id: id,
        };

        getNoticeBoard({ query, setNoticeBoard });
    }, []);

    const onClickUpdate = () => {
        navigate(`/notice/board/update/${id}`);
    };

    const onClickDelete = () => {
        const query = {
            id: id,
        };
        deleteNoticeBoard({ query });
    };

    const button = () => {
        return (
            <>
                <Li key={"write"}>
                    <Button onClick={() => onClickUpdate()}>수정</Button>
                </Li>
                <Li key={"update"}>
                    <Button onClick={() => onClickDelete()}>삭제</Button>
                </Li>
            </>
        );
    };

    return (
        <Layout>
            {noticeBoard && (
                <NoticeBoardBox>
                    <Box style={{ padding: "20px 0" }}>
                        <InputBox
                            label="제목"
                            type="text"
                            disabled={true}
                            width="100%"
                            labelWidth="15%"
                            height="50px"
                            id="noticeBoardTitle"
                            value={noticeBoard.noticeBoardTitle}
                        />
                    </Box>
                    <Editor
                        content={noticeBoard.noticeBoardContent}
                        setContent={null}
                        type={"notice"}
                        readOnly={true}
                    />
                </NoticeBoardBox>
            )}
            <ActionButton navi={null} button={button} type={"admin"} />
        </Layout>
    );
}

export default NoticeBoardDetail;
