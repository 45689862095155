import styled from "styled-components";

export const Button = styled.button`
    display: flex;
    width: 100px;
    height: 40px;
    font-size: 1rem;
    align-items: center;
    justify-content: center;
    color: black;
    border: 1px solid gray;
    border-radius: 10px;
    background-color: ${(props) => props.backgroundColor || "transparent"};
    box-sizing: border-box;

    @media (max-width: 768px) {
        width: 80px;
        font-size: 0.9rem;
    }
`;

export const ButtonBox = styled.div`
    display: flex;
    height: 100px;
    margin-right: 100px;
    align-items: center;
    justify-content: flex-end;
    text-align: ${(props) => props.textAlign || "start"};
    box-sizing: border-box;

    @media (max-width: 768px) {
        margin-right: 10px;
    }
`;

export const NaviUl = styled.ul`
    list-style-type: none;
    margin-block-start: 0;
    margin-block-end: 0;
    padding-inline-start: 10px;
    white-space: nowrap;
`;

export const NaviLi = styled.li`
    display: inline-block;
    margin-right: 10px;
`;
