import { useEffect, useRef } from "react";
import { Box } from "../../../component/box/css/styled.box";
import { useChangeImageHandler } from "../../../common/CommonUtil";
import { admin } from "../../../common/SessionUtil";

function DogProfileImage({ dog, setImage }) {
    const { source, image, onChangeImageHandler } = useChangeImageHandler();
    const imageRef = useRef();

    useEffect(() => {
        setImage(image);
    }, [source]);

    const onClickImage = () => {
        if (admin()) {
            imageRef.current.click();
        }
    };

    const imageBox = () => {
        return (
            <>
                {source === undefined && dog.dogImageJson === undefined ? (
                    <Box
                        style={{
                            display: "flex",
                            width: "100%",
                            height: "100%",
                            borderRadius: "20px",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                        onClick={() => onClickImage()}
                    >
                        이미지를 등록하세요
                    </Box>
                ) : null}
                {source === undefined &&
                dog.dogImageJson !== undefined &&
                dog.dogImageJson !== null ? (
                    <img
                        style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            borderRadius: "20px",
                        }}
                        src={dog.dogImageJson.fileUrl}
                        alt={dog.dogImageJson.fileName}
                        onClick={() => onClickImage()}
                    />
                ) : null}
                {source !== undefined ? (
                    <img
                        style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            borderRadius: "20px",
                        }}
                        src={source}
                        alt={"dog"}
                        onClick={() => onClickImage()}
                    />
                ) : null}
                <input
                    style={{ display: "none" }}
                    type="file"
                    ref={imageRef}
                    accept="image/*"
                    onChange={onChangeImageHandler}
                />
            </>
        );
    };

    return imageBox();
}

export default DogProfileImage;
