import React from "react";
import { PaginationBox, PaginationButton } from "./css/styled.Pagination";
import "./css/Pagination.css";

const Pagination = ({ currentPage, totalElements, onChangePage }) => {
    // const pages = [...Array(totalPages).keys()].map((num) => num + 1);

    // const Button = ({ onClick, disabled, name, height, width }) => {
    //     return (
    //         <button
    //             onClick={onClick}
    //             disabled={disabled}
    //             style={{
    //                 display: "flex",
    //                 height: height,
    //                 width: width,
    //                 alignContent: "center",
    //                 justifyContent: "center",
    //                 alignItems: "center",
    //                 border: "1px solid #D2B48C",
    //                 borderRadius: "10px",
    //                 margin: "0 5px",
    //                 backgroundColor: disabled ? null : "white",
    //             }}
    //         >
    //             {name}
    //         </button>
    //     );
    // };

    return (
        <PaginationBox>
            <PaginationButton
                activePage={currentPage} // 현재 페이지
                itemsCountPerPage={5} // 한 페이지랑 보여줄 아이템 갯수
                totalItemsCount={totalElements} // 총 아이템 갯수
                pageRangeDisplayed={5} // paginator의 페이지 범위
                marginPagesDisplayed={0}
                prevPageText={"‹"} // "이전"을 나타낼 텍스트
                nextPageText={"›"} // "다음"을 나타낼 텍스트
                onChange={onChangePage} // 페이지 변경을 핸들링하는 함수
                innerClass="pagination"
                itemClass="page-item"
                linkClass="page-link"
                activeClass="active"
                disabledClass="disabled"
            />
            {/* <Button
                onClick={() => onChangePage(1)}
                disabled={currentPage === 1}
                name="&lt; &lt;"
                width={"40px"}
                height={"40px"}
            />
            <Button
                onClick={() => onChangePage(currentPage - 1)}
                disabled={currentPage === 1}
                name="&lt;"
                width={"40px"}
                height={"40px"}
            />
            {pages.map((page) => (
                <Button
                    key={page}
                    onClick={() => onChangePage(page)}
                    disabled={page === currentPage}
                    name={page}
                    width={"40px"}
                    height={"40px"}
                />
            ))}
            <Button
                onClick={() => onChangePage(currentPage + 1)}
                disabled={currentPage === totalPages || totalPages === 0}
                name="&gt;"
                width={"40px"}
                height={"40px"}
            />
            <Button
                onClick={() => onChangePage(totalPages)}
                disabled={currentPage === totalPages || totalPages === 0}
                name="&gt; &gt;"
                width={"40px"}
                height={"40px"}
            /> */}
        </PaginationBox>
    );
};

export default Pagination;
