import { axiosDelete, axiosGet, axiosPatch, axiosPost } from "../CommonAPI";

export const getBoardHealthAPI = async ({ data, config }) =>
    axiosGet("/authority/health", data, config);

export const getNoticeBoardListAPI = async ({ data, config }) =>
    axiosGet("/board/notice/list", data, config);

export const postNoticeBoardAPI = async ({ data, config }) =>
    axiosPost("/board/notice", data, config);

export const getNoticeBoardAPI = async ({ data, config }) =>
    axiosGet("/board/notice", data, config);

export const getUpdateNoticeBoardAPI = async ({ data, config }) =>
    axiosGet("/board/notice/update", data, config);

export const patchNoticeBoardAPI = async ({ data, config }) =>
    axiosPatch("/board/notice", data, config);

export const deleteNoticeBoardAPI = async ({ data, config }) =>
    axiosDelete("/board/notice", data, config);
