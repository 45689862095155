import React from "react";
import LayoutHeader from "./header/Header";
import LayoutFooter from "./footer/Footer";
import { LayoutBody } from "./css/styled.layout";

function Layout(props) {
    return (
        <>
            <LayoutHeader button={props.button} />
            <LayoutBody>{props.children}</LayoutBody>
            <LayoutFooter />
        </>
    );
}

export default Layout;
