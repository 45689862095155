import styled from "styled-components";

export const DogProfileBox = styled.div`
    text-align: ${(props) => props.textAlign || "start"};
    display: flex;
    height: 600px;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

    @media (max-width: 768px) {
        flex-direction: column;
        height: 100%;
    }
`;

export const DogProfileImageBox = styled.div`
    width: 300px;
    height: 300px;
    border: 1px solid gray;
    border-radius: 20px;
`;

export const DogProfileDetailBox = styled.div`
    text-align: ${(props) => props.textAlign || "start"};
    display: flex;
    height: 600px;
    justify-content: center;
    align-items: center;

    @media (max-width: 768px) {
        flex-direction: column;
        height: 100%;
    }
`;

export const DogProfileLeftBox = styled.div`
    display: flex;
    height: 600px;
    margin-right: 100px;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;

    @media (max-width: 768px) {
        margin-right: 0;
        justify-content: center;
    }
`;

export const DogProfileRightBox = styled.div`
    display: flex;
    height: 600px;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
`;

export const DogProfileRightImageBox = styled.div``;
