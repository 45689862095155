import styled from "styled-components";

export const VolunteerListBox = styled.div`
    text-align: ${(props) => props.textAlign || "start"};
    display: flex;
    width: 800px;
    height: 700px;
    margin: 0px auto;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    @media (max-width: 768px) {
        width: 100%;
        height: 600px;
    }
`;

export const VolunteerBox = styled.div`
    text-align: ${(props) => props.textAlign || "start"};
    display: flex;
    width: 100%;
    height: 180px;
    margin-bottom: 10px;
    align-items: center;
    border: 1px solid #ccc;

    @media (max-width: 768px) {
        width: 80%;
        height: 100px;
    }
`;

export const VolunteerListImageBox = styled.div`
    text-align: ${(props) => props.textAlign || "start"};
    width: 150px;
    height: 150px;
    margin: 0 15px;
    border: 1px solid #ccc;
    border-radius: 20px;
    justify-content: center;
    align-items: center;

    @media (max-width: 768px) {
        width: 100%;
        height: 80px;
    }
`;

export const VolunteerEditorBox = styled.div`
    text-align: ${(props) => props.textAlign || "start"};
    padding: 0 200px;
    min-height: calc(100vh - 452px);

    @media (max-width: 768px) {
        height: 100%;
        padding: 0 20px;
    }
`;

export const VolunteerImageBox = styled.div`
    text-align: ${(props) => props.textAlign || "start"};
    display: flex;
    width: 450px;
    height: 350px;
    margin: 20px auto 0;
    align-items: center;
    border: 1px solid gray;
    border-radius: 20px;

    @media (max-width: 768px) {
        width: 300px;
        height: 200px;
    }
`;
