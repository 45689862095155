import { useMutation } from "react-query";
import {
    deleteVolunteerAPI,
    getShelterListAPI,
    getUpdateVolunteerAPI,
    getVolunteerAPI,
    getVolunteerListAPI,
    patchVolunteerAPI,
    postVolunteerAPI,
} from "../../../api/service/WithTheDogsServiceAPI";
import { useNavigate } from "react-router-dom";

function useVolunteerService() {
    const navigate = useNavigate();

    const { mutate: getShelterListMutate } = useMutation(getShelterListAPI);
    const { mutate: postVolunteerMutate } = useMutation(postVolunteerAPI);
    const { mutate: getVolunteerListMutate } = useMutation(getVolunteerListAPI);
    const { mutate: getVolunteerMutate } = useMutation(getVolunteerAPI);
    const { mutate: getUpdateVolunteerMutate } = useMutation(
        getUpdateVolunteerAPI
    );
    const { mutate: patchVolunteerMutate } = useMutation(patchVolunteerAPI);
    const { mutate: deleteVolunteerMutate } = useMutation(deleteVolunteerAPI);

    // shelter 목록
    const getShelterList = ({ query, setShelterList }) => {
        getShelterListMutate(
            { data: query },
            {
                onSuccess: (data) => {
                    const shelterList = data.response.map((data) => {
                        return {
                            title: data.shelterName,
                            value: data.shelterCode,
                        };
                    });
                    setShelterList(shelterList);
                },
                onError: (data) => {
                    console.log(data);
                },
            }
        );
    };

    // volunteer 등록
    const postVolunteer = (data) => {
        postVolunteerMutate(
            { data },
            {
                onSuccess: (data) => {
                    navigate("/withthedogs/volunteer");
                },
                onError: (data) => {
                    console.log(data);
                },
            }
        );
    };

    // volunteer 목록
    const getVolunteerList = ({ query, setVolunteerList, setPage }) => {
        getVolunteerListMutate(
            { data: query },
            {
                onSuccess: (data) => {
                    setPage(data.response);
                    setVolunteerList(data.response.list);
                },
                onError: (data) => {
                    console.log(data);
                },
            }
        );
    };

    // volunteer 상세
    const getVolunteer = ({ query, setVolunteer }) => {
        getVolunteerMutate(
            { data: query },
            {
                onSuccess: (data) => {
                    setVolunteer(data.response);
                },
                onError: (data) => {
                    console.log(data);
                },
            }
        );
    };

    // volunteer 수정 상세
    const getUpdateVolunteer = ({ query, setState }) => {
        getUpdateVolunteerMutate(
            { data: query },
            {
                onSuccess: (data) => {
                    setState(data.response);
                },
                onError: (data) => {
                    console.log(data);
                },
            }
        );
    };

    // volunteer 수정
    const patchVolunteer = (data) => {
        patchVolunteerMutate(
            { data },
            {
                onSuccess: (data) => {
                    navigate("/withthedogs/volunteer");
                },
                onError: (data) => {
                    console.log(data);
                },
            }
        );
    };

    // volunteer 삭제
    const deleteVolunteer = ({ query }) => {
        deleteVolunteerMutate(
            { data: query },
            {
                onSuccess: (data) => {
                    navigate("/withthedogs/volunteer");
                },
                onError: (data) => {
                    console.log(data);
                },
            }
        );
    };

    return {
        getShelterList,
        postVolunteer,
        getVolunteerList,
        getVolunteer,
        getUpdateVolunteer,
        patchVolunteer,
        deleteVolunteer,
    };
}

export default useVolunteerService;
