import { useMutation } from "react-query";
import {
    getUserInfoAPI,
    getUserPointAPI,
    postUserLoginAPI,
    postUserSignupAPI,
} from "../../api/service/UserServiceAPI";
import { useNavigate } from "react-router-dom";

function useUserService() {
    const navigate = useNavigate();

    const { mutate: postUserSignupMutate } = useMutation(postUserSignupAPI);
    const { mutate: postUserLoginMutate } = useMutation(postUserLoginAPI);
    const { mutate: getUserInfoMutate } = useMutation(getUserInfoAPI);
    const { mutate: getUserPointMutate } = useMutation(getUserPointAPI);

    // 회원가입
    const postUserSignup = (data) => {
        postUserSignupMutate(
            { data },
            {
                onSuccess: (data) => {
                    if (data.error) {
                        alert(data.error);
                    } else {
                        alert("회원가입이 완료되었습니다");
                        navigate("/");
                    }
                },
                onError: (data) => {
                    console.log(data);
                },
            }
        );
    };

    // 로그인
    const postUserLogin = (data) => {
        postUserLoginMutate(
            { data },
            {
                onSuccess: (data) => {
                    if (data.error) {
                        alert(data.error);
                    }

                    sessionStorage.setItem("token", data.response.token);
                    sessionStorage.setItem("userId", data.response.userId);
                    sessionStorage.setItem("userType", data.response.userType);

                    data.response.userRule.forEach((userRule) => {
                        const key = Object.keys(userRule)[0];
                        const value = userRule[key];
                        sessionStorage.setItem(key, value);
                    });

                    navigate("/");
                    window.location.reload();
                },
                onError: (data) => {
                    console.log(data);
                },
            }
        );
    };

    // 회원정보
    const getUserInfo = ({ data, setUserInfo }) => {
        getUserInfoMutate(
            { data },
            {
                onSuccess: (data) => {
                    setUserInfo(data.response);
                },
                onError: (data) => {
                    console.log(data);
                },
            }
        );
    };

    // 회원포인트
    const getUserPoint = ({ data, setPoint }) => {
        getUserPointMutate(
            { data },
            {
                onSuccess: (data) => {
                    setPoint(data.response.totalPoint);
                },
                onError: (data) => {
                    console.log(data);
                },
            }
        );
    };

    return { postUserSignup, postUserLogin, getUserInfo, getUserPoint };
}

export default useUserService;
