import ReactQuill, { Quill } from "react-quill";
import { useMemo, useRef } from "react";
import "react-quill/dist/quill.snow.css";
import "./css/board.css";
import { axiosPost } from "../../api/CommonAPI";
import { ImageActions } from "@xeger/quill-image-actions";
import { ImageFormats } from "@xeger/quill-image-formats";
import { v4 as uuidv4 } from "uuid";

Quill.register("modules/imageActions", ImageActions);
Quill.register("modules/imageFormats", ImageFormats);

function Editor({ content, setContent, fileType, readOnly = false }) {
    const toolbarOptions = [
        ["bold", "italic", "underline", "strike", "blockquote"], // toggled buttons
        ["link", "image", "video"],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ header: 1 }, { header: 2 }], // custom button values
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
        [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        [{ font: [] }],
        [{ align: [] }],
        ["clean"], // remove formatting button
    ];

    const formats = [
        "header",
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "list",
        "indent",
        "link",
        "image",
        "align",
        "color",
        "background",
        "float",
        "height",
        "width",
    ];

    const quillRef = useRef(null);

    const imageHander = () => {
        const date = new Date();
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        let today = year + "-" + month + "-" + day;

        let folderPath;
        switch (fileType) {
            case "board":
                folderPath = "/board/" + today + "/image";
                break;
            case "volunteer":
                folderPath = "/withthedogs/volunteer/" + today + "/image";
                break;
            default:
                folderPath = "/etc";
                break;
        }

        const input = document.createElement("input");
        input.setAttribute("type", "file");
        input.setAttribute("accept", "image/*");
        input.click();

        input.onchange = async () => {
            const file = input.files[0];
            if (file) {
                const extension = file.name.split(".").pop().toLowerCase();
                const fileName = uuidv4() + "." + extension;
                const files = new File([file], fileName.split("-").pop(), {
                    type: file.type,
                });

                try {
                    const formData = new FormData();
                    formData.append("userId", sessionStorage.getItem("userId"));
                    formData.append("fileType", fileType);
                    formData.append("folderPath", folderPath);
                    formData.append("files", files);
                    axiosPost("/file/upload/image", formData).then((result) => {
                        insertToEditor(result.response.fileList[0].fileUrl);
                    });
                } catch (error) {
                    console.error(error);
                }
            }
        };
    };

    const insertToEditor = (url) => {
        const quill = quillRef.current.getEditor();
        const range = quill.getSelection();
        quill.insertEmbed(range.index, "image", url);
    };

    const modules = useMemo(() => {
        if (readOnly) {
            return {
                toolbar: false,
            };
        } else {
            return {
                toolbar: {
                    container: toolbarOptions,
                    handlers: {
                        image: imageHander,
                    },
                    ImageResize: {
                        modules: ["Resize"],
                    },
                },
                imageActions: {},
                imageFormats: {},
            };
        }
    }, []);

    return (
        <ReactQuill
            theme="snow"
            ref={quillRef}
            readOnly={readOnly}
            modules={modules}
            formats={formats}
            value={content}
            onChange={setContent}
        />
    );
}

export default Editor;
