import { Box } from "../../box/css/styled.box";
import { Footer } from "../css/styled.layout";

function LayoutFooter() {
    const Copyright = () => {
        let href = "http://www.michael-faraday.co.kr";
        if (
            window.location.host ===
            ("www.withthedogs.co.kr" || "withthedogs.co.kr")
        ) {
            href = "http://www.withthedogs.co.kr";
        }

        return (
            <Box>
                {"All Copyright © "}
                <b>
                    <a href={href}>withbinary</a>
                </b>{" "}
                {new Date().getFullYear()}
                {"."}
            </Box>
        );
    };

    return (
        <Footer>
            <Box>
                <p>
                    This website runs on Ubuntu <br /> with Proxmox VE, Nginx
                    and Docker
                </p>
                <Copyright />
            </Box>
        </Footer>
    );
}

export default LayoutFooter;
