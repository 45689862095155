import styled from "styled-components";

export const Header = styled.header`
    display: flex;
    width: 100%;
    height: 100px;
    align-items: center;
    border-bottom: 2px solid gray;

    @media (max-width: 768px) {
        justify-content: center;
    }
`;

export const Nav = styled.nav``;

export const Ul = styled.ul`
    height: 30px;
    list-style-type: none;
    margin-block-start: 0;
    margin-block-end: 0;
    white-space: nowrap;
    line-height: 30px;

    @media (max-width: 768px) {
        padding-inline-start: 20px;
    }
`;

export const Li = styled.li`
    display: inline-block;
    margin-right: 10px;
`;

export const LayoutBody = styled.div`
    width: 100%;
    min-height: calc(100vh - 300px);
    border-bottom: 2px solid gray;
    margin: 0 auto;
    padding: 50px 0;

    @media (max-width: 768px) {
        padding: 50px 0;
    }
`;

export const Footer = styled.footer`
    display: flex;
    width: 100%;
    height: 150px;
    align-content: center;
    justify-content: center;
    align-items: center;
    overflow: auto;
`;
