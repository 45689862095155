import { Route } from "react-router-dom";
import NoticeBoardWrite from "../page/board/notice/NoticeBoardWrite";
import NoticeBoardUpdate from "../page/board/notice/NoticeBoardUpdate";
import NoticeBoardDetail from "../page/board/notice/NoticeBoardDetail";
import NoticeBoard from "../page/board/notice/NoticeBoard";

function BoardRouter() {
    return (
        <>
            <Route path="/notice" element={<NoticeBoard />} />
            <Route path="/notice/board/write" element={<NoticeBoardWrite />} />
            <Route path="/notice/board/:id" element={<NoticeBoardDetail />} />
            <Route
                path="/notice/board/update/:id"
                element={<NoticeBoardUpdate />}
            />
        </>
    );
}

export default BoardRouter;
