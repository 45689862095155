import { Navigate, Route } from "react-router-dom";
import Home from "../page/home/Home";

function MainRouter() {
    return (
        <>
            <Route path="/" element={<Navigate to="/home" />} />
            <Route path="/home" element={<Home />} />
        </>
    );
}

export default MainRouter;
