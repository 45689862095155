import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../../component/layout/Layout";

function Error() {
    const navigate = useNavigate();

    useEffect(() => {
        alert("권한이 없습니다");
        navigate("/");
    }, []);
    return (
        <Layout>
            <h1>Not Found</h1>
        </Layout>
    );
}

export default Error;
