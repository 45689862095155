import { axiosDelete, axiosGet, axiosPatch, axiosPost } from "../CommonAPI";

// dog 저장
export const postDogAPI = async ({ data, config }) =>
    axiosPost("/withthedogs/dog", data, config);

// dog 목록
export const getDogListAPI = async ({ data, config }) =>
    axiosGet("/withthedogs/dog/list", data, config);

// dog 상세
export const getDogAPI = async ({ data, config }) =>
    axiosGet("/withthedogs/dog", data, config);

// dog 수정
export const patchDogAPI = async ({ data, config }) =>
    axiosPatch("/withthedogs/dog", data, config);

// dog 삭제
export const deleteDogAPI = async ({ data, config }) =>
    axiosDelete("/withthedogs/dog", data, config);

// 강아지 이미지 목록
export const getDogImageListAPI = async ({ data, config }) =>
    axiosGet("/withthedogs/dog/image", data, config);

// shelter 목록
export const getShelterListAPI = async ({ data, config }) =>
    axiosGet("/withthedogs/shelter/list", data, config);

// volunteer 저장
export const postVolunteerAPI = async ({ data, config }) =>
    axiosPost("/withthedogs/volunteer", data, config);

// volunteer 목록
export const getVolunteerListAPI = async ({ data, config }) =>
    axiosGet("/withthedogs/volunteer/list", data, config);

// volunteer 상세
export const getVolunteerAPI = async ({ data, config }) =>
    axiosGet("/withthedogs/volunteer", data, config);

// volunteer 수정 상세
export const getUpdateVolunteerAPI = async ({ data, config }) =>
    axiosGet("/withthedogs/volunteer/update", data, config);

// volunteer 수정
export const patchVolunteerAPI = async ({ data, config }) =>
    axiosPatch("/withthedogs/volunteer", data, config);

// volunteer 삭제
export const deleteVolunteerAPI = async ({ data, config }) =>
    axiosDelete("/withthedogs/volunteer", data, config);
