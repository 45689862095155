import { useState } from "react";

export const useChangeHandler = (init) => {
    const [state, setState] = useState(init);

    const onChangeHandler = (e) => {
        const { name, value } = e.target;

        setState({ ...state, [name]: value });
    };

    return { state, setState, onChangeHandler };
};

export const useChangeImageHandler = () => {
    const [source, setSource] = useState();
    const [image, setImage] = useState();

    const onChangeImageHandler = (e) => {
        const { files } = e.target;
        const file = files[0];
        setImage(file);
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            setSource(reader.result);
        };
    };

    return { source, image, setImage, onChangeImageHandler };
};
