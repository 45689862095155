import { Route } from "react-router-dom";
import UserSignup from "../page/user/signup/UserSignup";
import UserLogin from "../page/user/login/UserLogin";

function UserRouter() {
    return (
        <>
            <Route path="/user/signup" element={<UserSignup />} />
            <Route path="/user/login" element={<UserLogin />} />
        </>
    );
}

export default UserRouter;
