import { useNavigate } from "react-router-dom";
import Layout from "../../../component/layout/Layout";
import { Button } from "../../../component/button/css/styled.button";
import { Li } from "../../../component/layout/css/styled.layout";
import { ActionButton } from "../../../component/button/ActionButton";
import useVolunteerService from "./useVolunteerService";
import { useEffect, useState } from "react";
import {
    VolunteerBox,
    VolunteerListImageBox,
    VolunteerListBox,
} from "./css/styled.volunteer";
import { Box } from "../../../component/box/css/styled.box";
import Pagination from "../../../component/pagination/Pagination";

function Volunteer() {
    const navigate = useNavigate();

    const [currentPage, setCurrentPage] = useState(1);
    const [page, setPage] = useState();
    const [volunteerList, setVolunteerList] = useState([]);
    const { getVolunteerList } = useVolunteerService();
    useEffect(() => {
        const query = {
            page: currentPage - 1,
            size: 5,
        };

        getVolunteerList({ query, setVolunteerList, setPage });
    }, [currentPage]);

    const onChagePage = (page) => {
        setCurrentPage(page);
    };

    const onClickVolunteer = (id) => {
        navigate(`/withthedogs/volunteer/${id}`);
    };

    const onClickWrite = () => {
        navigate("/withthedogs/volunteer/write");
    };

    const button = () => {
        return (
            <Li key={"write"}>
                <Button onClick={() => onClickWrite()}>글쓰기</Button>
            </Li>
        );
    };

    return (
        <Layout>
            <VolunteerListBox>
                {volunteerList.map((item) => (
                    <VolunteerBox
                        key={item.id}
                        onClick={() => onClickVolunteer(item.id)}
                    >
                        <VolunteerListImageBox>
                            {item.volunteerImageJson !== undefined ? (
                                <img
                                    style={{
                                        width: "100%",
                                        height: "100%",
                                        borderRadius: "20px",
                                        overflow: "hidden",
                                        objectFit: "cover",
                                    }}
                                    src={item.volunteerImageJson.fileUrl}
                                    alt={item.volunteerImageJson.fileName}
                                />
                            ) : (
                                <Box
                                    style={{
                                        display: "flex",
                                        width: "100%",
                                        height: "100%",
                                        borderRadius: "20px",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    이미지 없음
                                </Box>
                            )}
                        </VolunteerListImageBox>
                        <Box style={{ width: "650px" }}>
                            <Box>{item.volunteerTitle}</Box>
                            <Box>{item.shelterName}</Box>
                            <Box>{item.volunteerDate}</Box>
                        </Box>
                    </VolunteerBox>
                ))}
            </VolunteerListBox>
            {page && (
                <Pagination
                    currentPage={currentPage}
                    totalElements={page.totalElements}
                    onChangePage={onChagePage}
                />
            )}
            <ActionButton button={button} />
        </Layout>
    );
}

export default Volunteer;
