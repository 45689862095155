import { useCallback } from "react";
import useUserService from "../useUserService";
import { signupForm } from "./init";
import { useChangeHandler } from "../../../common/CommonUtil";
import Layout from "../../../component/layout/Layout";
import { Box } from "../../../component/box/css/styled.box";
import { Button } from "../../../component/button/css/styled.button";

function UserSignup() {
    const { state, onChangeHandler } = useChangeHandler(signupForm);
    const { postUserSignup } = useUserService();

    const onClickSignup = useCallback(() => {
        postUserSignup(state);
    });

    return (
        <Layout>
            <Box>
                <Box>
                    <Box>회원가입</Box>
                    <Box>
                        <Box>
                            <Box>
                                <input
                                    type="text"
                                    id="userId"
                                    name="userId"
                                    onChange={onChangeHandler}
                                />
                            </Box>
                            <Box>
                                <input
                                    type="password"
                                    id="userPassword"
                                    name="userPassword"
                                    onChange={onChangeHandler}
                                />
                            </Box>
                            <Box item xs={12}>
                                <input
                                    id="userName"
                                    name="userName"
                                    onChange={onChangeHandler}
                                />
                            </Box>
                        </Box>
                        <Button onClick={onClickSignup}>회원가입</Button>
                    </Box>
                </Box>
            </Box>
        </Layout>
    );
}

export default UserSignup;
