import { admin } from "../../../common/SessionUtil";
import { SelectBox } from "../../../component/form/Form";

export const dogForm = {
    shelterCode: "",
    dogName: "",
    dogInfo: "",
    likePeople: "",
    dogLeaveYn: "N",
};

export const showLikePeople = (disabled, likePeople, setLikePeople) => {
    const onClickLike = (i) => {
        if (disabled && admin()) {
            setLikePeople(i + 1);
        }
    };

    let likePeopleArray = [];

    for (let i = 0; i < 5; i++) {
        if (likePeople > i) {
            likePeopleArray.push(
                <span key={i} onClick={() => onClickLike(i)}>
                    ♥
                </span>
            );
        } else {
            likePeopleArray.push(
                <span key={i} onClick={() => onClickLike(i)}>
                    ♡
                </span>
            );
        }
    }

    return (
        <div
            style={{
                display: "flex",
                width: "300px",
                height: "40px",
                justifyContent: "space-around",
                alignItems: "center",
            }}
        >
            <label
                style={{
                    width: "30%",
                    fontSize: "16px",
                    textAlign: "center",
                }}
                htmlFor={"likePeople"}
            >
                호감도
            </label>
            <div
                style={{
                    width: "70%",
                    height: "30px",
                    fontSize: "16px",
                    color: "black",
                    lineHeight: "30px",
                }}
            >
                {likePeopleArray}
            </div>
        </div>
    );
};

export const dogLeaveButton = (state, onChangeHandler) => {
    const options = [
        {
            title: "NO",
            value: "Y",
        },
        {
            title: "YES",
            value: "N",
        },
    ];

    return (
        <SelectBox
            label="보호여부"
            width="300px"
            labelWidth="30%"
            height="40px"
            id="dogLeaveYn"
            value={state.dogLeaveYn}
            onChangeHandler={onChangeHandler}
            options={options}
        />
    );
};
