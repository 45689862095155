export const InputBox = ({
    label,
    type = "text",
    disabled = false,
    width = "600px",
    height = "40px",
    labelWidth = "100%",
    id,
    value,
    onChangeHandler,
}) => {
    return (
        <div
            style={{
                display: "flex",
                width: width,
                height: height,
                justifyContent: "space-around",
                alignItems: "center",
            }}
        >
            <label
                style={{
                    width: labelWidth,
                    fontSize: "16px",
                    textAlign: "center",
                }}
                htmlFor={id}
            >
                {label}
            </label>
            <input
                style={{
                    width: `calc(100% - ${labelWidth})`,
                    height: `calc(${height} - 10px)`,
                    fontSize: "16px",
                    color: "black",
                }}
                type={type}
                disabled={disabled}
                id={id}
                name={id}
                defaultValue={value}
                onChange={onChangeHandler}
            />
        </div>
    );
};

export const TextareaBox = ({
    label,
    type = "text",
    disabled = false,
    width = "600px",
    height = "40px",
    labelWidth = "100%",
    id,
    value,
    onChangeHandler,
}) => {
    return (
        <div
            style={{
                display: "flex",
                width: width,
                height: height,
                justifyContent: "space-around",
                alignItems: "center",
            }}
        >
            <label
                style={{
                    width: labelWidth,
                    fontSize: "16px",
                    textAlign: "center",
                }}
                htmlFor={id}
            >
                {label}
            </label>
            <textarea
                style={{
                    width: `calc(100% - ${labelWidth})`,
                    height: `calc(${height} - 10px)`,
                    fontSize: "16px",
                    color: "black",
                    resize: "none",
                }}
                type={type}
                disabled={disabled}
                id={id}
                name={id}
                defaultValue={value}
                onChange={onChangeHandler}
            />
        </div>
    );
};

export const SelectBox = ({
    label,
    disabled = false,
    width = "600px",
    height = "40px",
    labelWidth = "100%",
    id,
    defaultValue,
    value,
    onChangeHandler,
    options = [],
}) => {
    return (
        <div
            style={{
                display: "flex",
                width: width,
                height: height,
                justifyContent: "space-around",
                alignItems: "center",
            }}
        >
            <label
                style={{
                    width: labelWidth,
                    fontSize: "16px",
                    textAlign: "center",
                }}
                htmlFor={id}
            >
                {label}
            </label>
            <select
                style={{
                    width: `calc(100% - ${labelWidth})`,
                    height: `calc(${height} - 10px)`,
                    fontSize: "16px",
                    color: "black",
                }}
                disabled={disabled}
                id={id}
                name={id}
                defaultValue={defaultValue}
                value={value}
                onChange={onChangeHandler}
            >
                {options.map((item) => (
                    <option key={item.value} value={item.value}>
                        {item.title}
                    </option>
                ))}
            </select>
        </div>
    );
};
