export const withbinary = () => {
    return (
        window.location.host === "michael-faraday.co.kr" ||
        window.location.host === "www.michael-faraday.co.kr"
    );
};

export const withbinaryAdmin = () => {
    return sessionStorage.getItem("withbinary") === "manager";
};
