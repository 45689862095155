import { useParams } from "react-router-dom";
import Layout from "../../../component/layout/Layout";
import { useEffect, useState } from "react";
import { useChangeHandler } from "../../../common/CommonUtil";
import { dogForm, showLikePeople } from "./DogUtil";
import useDogsService from "./useDogsService";
import {
    DogProfileDetailBox,
    DogProfileImageBox,
    DogProfileLeftBox,
    DogProfileRightBox,
    DogProfileRightImageBox,
} from "./css/styled.DogProfile";
import DogProfileImage from "./DogProfileImage";
import { Box } from "../../../component/box/css/styled.box";
import { InputBox, SelectBox } from "../../../component/form/Form";
import useShelterService from "../shelter/useShelterService";

function DogProfile() {
    let { shelterCode, dogCode } = useParams();
    const { state, setState, onChangeHandler } = useChangeHandler(dogForm);
    const { getDog, getDogImageList } = useDogsService();
    const { getShelterList } = useShelterService();

    const [disabled, setDisabled] = useState(false);
    const [image, setImage] = useState();
    const [likePeople, setLikePeople] = useState(1);

    const [shelterList, setShelterList] = useState([]);
    const [dogImageList, setDogImageList] = useState([]);

    useEffect(() => {
        const query = {
            shelterCode: shelterCode,
            dogCode: dogCode,
        };
        getDog({ query, setState, setLikePeople });
        getDogImageList({ query, setDogImageList });

        getShelterList({ query, setShelterList });
    }, []);

    useEffect(() => {
        if (shelterList.length > 0) {
            state.shelterCode = shelterList[0].value;
        }
    }, [shelterList.length > 0]);

    console.log(dogImageList);

    return (
        <Layout>
            <DogProfileDetailBox>
                <DogProfileLeftBox>
                    <DogProfileImageBox>
                        <DogProfileImage dog={state} setImage={setImage} />
                    </DogProfileImageBox>
                    <Box>
                        <InputBox
                            label="이름"
                            type="text"
                            width="300px"
                            labelWidth="30%"
                            height="40px"
                            disabled={true}
                            id="dogName"
                            value={state.dogName}
                            onChangeHandler={onChangeHandler}
                        />
                    </Box>
                    <Box>
                        <SelectBox
                            label="보호소"
                            width="300px"
                            labelWidth="30%"
                            height="50px"
                            disabled={true}
                            id="shelterCode"
                            value={state.shelterCode}
                            onChangeHandler={onChangeHandler}
                            options={shelterList}
                        />
                    </Box>
                    <Box>
                        {showLikePeople(false, likePeople, setLikePeople)}
                    </Box>
                </DogProfileLeftBox>
                <DogProfileRightBox>
                    <DogProfileRightImageBox>
                        {dogImageList.map((item) => {
                            <img
                                style={{
                                    width: "100%",
                                    height: "100%",
                                    borderRadius: "20px",
                                    overflow: "hidden",
                                    objectFit: "cover",
                                }}
                                src={item.fileUrl}
                                alt={item.fileName}
                            />;
                        })}
                    </DogProfileRightImageBox>
                </DogProfileRightBox>
            </DogProfileDetailBox>
        </Layout>
    );
}

export default DogProfile;
