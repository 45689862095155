import Layout from "../../component/layout/Layout";
import { HomeImageBox } from "./css/styled.Home";

function Home() {
    return (
        <Layout>
            <HomeImageBox>대문 이미지</HomeImageBox>
        </Layout>
    );
}

export default Home;
